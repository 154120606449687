import React from 'react';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { axios_instance } from 'components/axios';
import StringResources from "components/string-resources";

export default class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  }

  componentDidMount() {
    let currentUser = this.props.location.state === undefined ? undefined : this.props.location.state[0];
    this.setState({
      id: currentUser == undefined ? 0 : currentUser
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const errorMessages = [
      { condition: this.isFormInvalid(), message: StringResources.common.missing_fields },
      { condition: this.passwordConfirmation(), message: StringResources.register.passwordMismatch }
    ];

    for (const { condition, message } of errorMessages) {
      if (condition) {
        NotificationManager.error(
          StringResources.common.error,
          message,
          3000
        );

        return;
      }
    }

    const params = this.getParams();
    this.submitForm(params);
  }

  isFormInvalid = () => {
    const { id, email, password, name, lastName, phone } = this.state;
    return !password || (id === 0 && (!email || !name || !lastName || !phone));
  }

  passwordConfirmation = () => {
    const { password, confirmPassword} = this.state;
    return !(password == confirmPassword);
  }

  getParams = () => {
    const { id, email, password, confirmPassword, name, lastName, phone } = this.state;
    return id === 0 ? {
      email,
      password,
      name,
      last_name: lastName,
      phone,
      country_code_id: "MX"
    } : {
      password
    };
  }

  submitForm = (params) => {
    const { id } = this.state;
    showLoader();
    const request = id === 0 
      ? axios_instance.post('/v1/users', { user: params })
      : axios_instance.put(`/v1/users/${id}`, { user: params });

    request.then(() => {
      NotificationManager.success(
        StringResources.common.success,
        "Informacion guardada",
        3000
      );
      this.props.history.goBack();
    }).catch(() => {
      hideLoader();
    }).finally(() => {
      hideLoader();
    });
  }

  render() {
    return(
      <div className="session-container">
        <div className="flex-column">
            <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header session-title">
                {
                  this.state.id == 0 ? "Registrar Usuario" : "Cambiar Contraseña"
                }
              </span>
              <hr />
            </div>
          <form onSubmit={this.handleSubmit} className="flex-column">
            <div style={{alignItems: 'center'}} className="flex-column">
              {
                this.state.id != 0 ? "" : 
                <div className="flex-column">
                  <div className="form-group-sign-in">
                    <input type="name" className="form-control bigger-input" id="name" name="name" placeholder="Nombre" onChange={this.handleInputChange} />
                  </div>
                  <div className="form-group-sign-in">
                    <input type="name" className="form-control bigger-input" id="lastName" name="lastName" placeholder="Apellidos" onChange={this.handleInputChange} />
                  </div>
                  <div className="form-group-sign-in">
                    <input type="email" className="form-control bigger-input" id="email" name="email" placeholder="Correo Electrónico" onChange={this.handleInputChange} />
                  </div>
                  <div className="form-group-sign-in">
                    <input type="phone" className="form-control bigger-input" id="phone" name="phone" placeholder="Teléfono" onChange={this.handleInputChange} />
                  </div>
                </div>
              }
              <div className="form-group-sign-in">
                <input type="password" className="form-control bigger-input" id="password" name="password" placeholder={StringResources.register.password} onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="password" className="form-control bigger-input" id="confirmPassword" name="confirmPassword" placeholder={StringResources.register.password_confirmation} onChange={this.handleInputChange} />
              </div>
            </div>
            <button type="submit" className="sign-in-btn">
              <strong>Confirmar</strong>
            </button>
          </form>
        </div>
      </div>
    )
  }
}
